.triot-band-members {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px;
}

.triot-band-members * {
	font-family: 'Merriweather Sans', sans-serif;
    line-height: 26px;
    font-size: 15px;
}




